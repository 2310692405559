import 'modern-normalize/modern-normalize.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, storeWithPersister } from './redux/store';
// Импорт для СЕО:
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          {/* Это запись в наше LocaleStorage: */}
          <PersistGate loading={null} persistor={storeWithPersister}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// TODO: У Репеты почему то было сначала: Провайдер, Персист, Броузер, АПП

// import { BrowserRouter } from 'react-router-dom';

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       {/* <PersistGate loading={null} persistor={storeWithPersister}> */}
//       <BrowserRouter>
//         <App />
//         </BrowserRouter>
//       {/* </PersistGate> */}
//       </Provider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );